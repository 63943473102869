.workSchedule {
  overflow: auto;
}

.workSchedule .rct-sidebar {
  width: 180px !important;
}

.workSchedule .rct-sidebar div {
  width: auto !important;
  background-color: inherit !important;
}

.workSchedule .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background-color: none;
}

.workSchedule .rct-hl-odd {
  background-color: white !important;
}

.workSchedule .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background-color: inherit;
}

.bg-darkWhite {
  background-color: inherit;
}

.workSchedule .rct-vl-first {
  border-left: 1px !important;
}

.workSchedule .rct-calendar-header {
  border: none !important;
  
}

.workSchedule .rbc-selected {
  background-color: #EE3859 !important;

 
}


.workSchedule .rbc-event {
  /* border: 1px solid lightblue !important; */
  
  @apply bg-transparent text-gray
  /* color: #EE3859 !important; */
}

.workSchedule .rbc-row-bg {
  @apply z-10 cursor-pointer
}

.workSchedule .rbc-month-view {
  border-left: none;
  border-right: none;
}

.workSchedule .rbc-date-cell {
  @apply flex justify-end
}

.workSchedule .rbc-today {
  @apply bg-inherit
}

.workSchedule .rbc-month-view {
  min-width: 360px;
}

.workSchedul .rbc-event {
  @apply bg-transparent text-gray
}

.rbc-event .rbc-selected {
height: 100%;

}